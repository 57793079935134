.section {
  width: 90%;
  max-width: 600px;
  margin: 5px;
  border-radius: 7px;
  padding: 5px;
  justify-self: center;
  align-self: center;
  align-items: center;
  -webkit-box-shadow: 0px 7px 22px 6px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 7px 22px 6px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 7px 22px 6px rgba(0, 0, 0, 0.1);
  justify-content: flex-start;
}

@media only screen and (min-width: 1280px) {
  .main .section {
    min-height: 450px;
    align-self: auto;
  }
}

.section.light {
  background-color: var(--BASE_LVL_TWO_GRAY);
}

.section.dark {
  background-color: var(--BASE_LVL_ONE_DARK);
}

.section .header {
  font-family: "Bungee-Regular";
  display: flex;
  justify-content: center;
}

.section.light .header {
  color: var(--BASE_ACCENT);
}

.section.grow-height {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-start;
}

.section-content {
  display: flex;
  flex-direction: column;
  justify-items: center;
  width: 90%;
  /* margin-left: 5%; */
  /* margin-right: 5%; */
  justify-content: center;
}

.section-content.grow-height {
  flex-grow: 1;
  justify-content: start;
  max-height: 80vh;
}

.row-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 5px; */
  justify-content: center;
}

.row-content.space-between {
  justify-content: space-between;
}

.row-content.no-margin-bottom {
  margin-bottom: 0 !important;
}

.menu-page .row-content {
  margin-bottom: 5px;
}
