header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

header > img {
  width: 60%;
  max-width: 400px;
  margin-bottom: 10px;
}

.spacer {
  width: 64px;
}

.header-title-container > img {
  width: 60%;
  max-width: 400px;
  margin-bottom: 10px;
}

.header-container {
  display: flex;
  flex-direction: column;
}

.header-title-container {
  display: flex;
  flex-direction: row;
}

.header-button-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-evenly;
  width: 95%;
  min-width: 400px;
  max-width: 745px;
}
