@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Bungee-Regular";
  src: local("Bungee-Regular"),
    url(./Assets/Fonts/Bungee-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Oswald-Regular";
  src: local("Oswald-Regular"),
    url(./Assets/Fonts/Oswald-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BenchNine-Regular";
  src: local("BenchNine-Regular"),
    url(./Assets/Fonts/BenchNine-Regular.ttf) format("truetype");
}

:root {
  --BASE_ACCENT: #6614b3;

  --BASE_LVL_TWO_GRAY: #dbdbdb;
  --BASE_LVL_TWO_GRAY_WITH_OPACITY: rgba(153, 153, 153, 0.75);
  --BASE_LVL_THREE_GRAY: #d0d0d0;
  --BASE_LVL_FOUR_GRAY: #c7c7c7;

  --BASE_WHITE: #ffffff;
  --BASE_WHITE_DULLED: #c9c5c5;
  --BASE_GRAY: hsl(0, 0%, 60%);
  --BASE_DARK_GRAY: #40444a;
  --BASE_BLACK: #000000;
  --BASE_LIGHT_GRAY: #bcbcbc;

  --BASE_LVL_ONE_DARK_WITH_OPACITY: rgba(18, 18, 18, 0.75);
  --BASE_LVL_ONE_DARK: #1e1e1e;
  --BASE_LVL_TWO_DARK: #252526;
  --BASE_LVL_THREE_DARK: #2d2d30;
  --BASE_LVL_FOUR_DARK: #3e3e42;

  --BASE_BLUE: #255295;
  --BASE_GREEN: #2e8d35;
  --BASE_RED: #8d2e30;
  --BASE_PINK: #df1077;
  --BASE_YELLOW: #f3b800;
  --BASE_ORANGE: #da6900;
  --BASE_AQUA: #2e8d8a;
  --BASE_PURPLE: #6614b3;

  --BASE_SELECTABLE_LIGHT: var(--BASE_DARK_GRAY);
  --BASE_SELECTABLE_DARK: var(--BASE_BLACK);
}

::-moz-selection {
  /* Code for Firefox */
  color: white;
  background: var(--BASE_ACCENT);
}

::selection {
  color: white;
  background: var(--BASE_ACCENT);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 550px) {
  main.main {
    margin-left: 20px;
    margin-right: 20px;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: center;
    align-content: flex-start;
  }
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.large-label {
  font-family: "Oswald-Regular";
  font-size: 14pt;
}

.warningText {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  text-align: center;
  margin-left: 12px;
  margin-right: 12px;
  line-height: 14px;
  margin-bottom: 5px;
}

button {
  cursor: pointer;
}

input {
  border-width: 2px;
  border-color: var(--BASE_ACCENT);
  border-style: solid;
  height: 40px;
  font-family: "Oswald-Regular";
  font-size: 12pt;
  text-indent: 5px;
}

input.input-smallest {
  color: var(--BASE_BLACK);
  border-radius: 0;
  overflow: hidden;
  width: 60px;
  text-align: center;
}

input.input-wide {
  width: 224px;
  height: 40px;
  /* position: relative; */
  font-family: "Oswald-Regular";
  font-size: 1rem;
  box-sizing: border-box;
  min-width: 224px;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

input.input-flex-wide {
  width: 424px;
  height: 40px;
  /* position: relative; */
  font-family: "Oswald-Regular";
  font-size: 1rem;
  box-sizing: border-box;
  min-width: 224px;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

input.input-widest {
  width: 360px;
  height: 40px;
  /* position: relative; */
  font-family: "Oswald-Regular";
  font-size: 1rem;
  box-sizing: border-box;
  min-width: 224px;
  padding: 8px 12px;
  border-radius: 4px;
  text-align: left;
  line-height: 1.5;
  background: #fff;
  border: 1px solid #dae2ed;
  color: #1c2025;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
}

button.small-rectangle {
  width: 60px;
  height: 40px;
  border-radius: 4px;
  background-color: var(--BASE_ACCENT);
  color: var(--BASE_WHITE);
  font-family: "Bungee-Regular";
  font-size: 10pt;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

button.large-rectangle {
  width: 88px;
  height: 42px;
  border-radius: 4px;
  background-color: var(--BASE_ACCENT);
  color: var(--BASE_WHITE);
  font-family: "Bungee-Regular";
  font-size: 10pt;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  line-height: 1;
}

button.large-rectangle-no-color {
  width: 73px;
  height: 39px;
  border-radius: 4px;
  font-family: "Bungee-Regular";
  font-size: 8pt;
  border: none;
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  line-height: 1;
}

button.wide-rectangle {
  width: 100px;
  height: 40px;
  margin: 1px;
  border-radius: 4px;
  background-color: var(--BASE_ACCENT);
  color: var(--BASE_WHITE);
  font-family: "Bungee-Regular";
  font-size: 8pt;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

button.large-square {
  width: 50px;
  height: 50px;
  margin: 15px;
  border-radius: 4px;
  background-color: var(--BASE_ACCENT);
  color: var(--BASE_WHITE);
  font-family: "Bungee-Regular";
  font-size: 8pt;
  border: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  line-height: 1;
}

button.accent {
  background-color: var(--BASE_ACCENT);
}

button.warning {
  background-color: var(--BASE_ORANGE);
}

button.danger {
  background-color: var(--BASE_RED);
}

button.new {
  background-color: var(--BASE_GREEN);
}

button.special {
  background-color: var(--BASE_ORANGE);
}

button.info {
  background-color: var(--BASE_BLUE);
}

button.fun {
  background-color: var(--BASE_PINK);
}

button.disabled {
  background-color: var(--BASE_GRAY);
}

.dark button.disabled {
  background-color: var(--BASE_DARK_GRAY);
}

.column100 {
  width: 100% !important;
}
.column90 {
  width: 90% !important;
}
.column85 {
  width: 85% !important;
}
.column80 {
  width: 80% !important;
}
.column70 {
  width: 70% !important;
}
.column60 {
  width: 60% !important;
}
.column56 {
  width: 56% !important;
}
.column55 {
  width: 55% !important;
}
.column50 {
  width: 50% !important;
}
.column40 {
  width: 40% !important;
}
.column33 {
  width: 33% !important;
}
.column30 {
  width: 30% !important;
}
.column25 {
  width: 25% !important;
}
.column20 {
  width: 20% !important;
}
.column15 {
  width: 15% !important;
}
.column10 {
  width: 10% !important;
}

.columnMax {
  width: 6000% !important;
}

.logHeaderText {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  text-align: center;
  line-height: 12px;
}

.logItemText {
  font-family: "BenchNine-Regular";
  font-size: 16pt;
  line-height: 16px;
  text-align: center;
}

.TableArrowHeader {
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.logTable {
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.logTableHeaderRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 5px;
}

.logTableRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-self: stretch;
  margin-bottom: 2px;
  border-radius: 4px;
  height: 28px;
  align-items: center;
  border: none;
  padding-top: 6px;
  padding-bottom: 6px;
}

.logTableRowStub {
  justify-content: center;
  border-radius: 4px;
  min-width: 100%;
  height: 28px;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 6px;
}

.logItemTextStub {
  font-family: "BenchNine-Regular";
  font-size: 16pt;
  line-height: 16px;
  text-align: center;
}

.logItemTextStubPlanDark {
  color: #c9c5c5;
}
.logItemTextStubPlanLight {
  color: black;
}

.preppedRow {
  background-color: var(--BASE_ACCENT);
}

.selectedRow {
  background-color: var(--BASE_ACCENT);
  cursor: pointer;
}

.selectedRowText {
  color: var(--BASE_WHITE);
}

.dark .unselectedRow {
  background-color: var(--BASE_DARK_GRAY);
}

.unselectedRow {
  background-color: var(--BASE_LIGHT_GRAY);
  cursor: pointer;
}

.dark .unselectedRowText {
  color: var(--BASE_WHITE_DULLED);
}

.unselectedRowText {
  color: var(--BASE_BLACK);
}

.dark .selectableRow {
  background-color: var(--BASE_SELECTABLE_DARK);
}

.selectableRow {
  background-color: var(--BASE_LIGHT_GRAY);
  cursor: pointer;
}

.dark .selectableRowText {
  color: var(--BASE_WHITE_DULLED);
}

.selectableRowText {
  color: var(--BASE_BLACK);
}

.sectionItemRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* min-width: 400px; */
  margin-bottom: 5px;
  align-items: center;
}

.endRow {
  flex-direction: row;
}

.annotations {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.annotation-item {
  margin: 1px;
  height: 30px;
  border-radius: 3px;
  border: none;
  background-color: grey;
  text-align: center;
  width: 60px;
}

.container-centered {
  align-items: center;
}

.image {
  width: 50%;
}

.imageLeft {
  margin-right: 2px;
  margin-left: -2px;
}
.imageRight {
  margin-left: 2px;
}

.imageRow {
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
}

.instructionText {
  font-family: "Oswald-Regular";
  font-style: "normal";
  font-weight: 400;
  font-size: 14pt;
}

.ReactModal__Content--after-open {
  position: static !important;
  background-color: var(--BASE_LVL_TWO_GRAY) !important;
  align-self: center;
  overflow: hidden !important;
  max-width: 520px;
  width: 83%;
}

body.dark .ReactModal__Content--after-open {
  background-color: var(--BASE_LVL_ONE_DARK) !important;
  border: none !important;
}

.ReactModal__Overlay--after-open {
  background-color: var(--BASE_LVL_TWO_GRAY_WITH_OPACITY) !important;
}

body.dark .ReactModal__Overlay--after-open {
  background-color: var(--BASE_LVL_ONE_DARK_WITH_OPACITY) !important;
}

.MuiSlider-rail,
.MuiSlider-track,
.MuiSlider-thumb {
  color: var(--BASE_ACCENT);
}

.MuiSlider-thumb.Mui-focusVisible,
.MuiSlider-thumb:hover {
  color: var(--BASE_ACCENT);
}

.MuiSlider-thumb:active,
.MuiSlider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(102, 20, 179, 0.3) !important;
}

.infoIcon {
  color: var(--BASE_ACCENT);
}

.infoIconButton {
  width: 40px;
}

.apple-sign-in {
  width: 170px;
}

.google-sign-in {
  margin-left: 4px;
}

.back-to-weighzy {
  max-width: 80px;
  color: var(--BASE_PURPLE);
}

.small-ad {
  width: 100%;
  height: 100%;
}

.AdSlot {
  height: 445px;
  width: 595px;
  display: block;
}

@media (max-width: 1000px) {
  .AdSlot {
    width: 535px;
    height: 410px;
  }
}

@media (max-width: 750px) {
  .AdSlot {
    width: 535px;
    height: 410px;
  }
}
