.workoutContentContainer {
  flex-direction: column;
  justify-content: space-between;
}

.arrowContainer {
  flex-direction: column;
  justify-content: center;
  width: 20%;
  align-items: center;
}

.infoContainer {
  flex-direction: row;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
}

.topButtons {
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 20px;
}

.topButton {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}

.topButtonIconRaise {
  margin-top: -5px;
}

.topButtonIcon {
  margin-top: -5px;
}

.workoutNavLabel {
  text-align: center;
  flex-wrap: wrap;
  width: 120px;
  line-height: 20px;
}

.missingGoal {
  color: var(--BASE_GREEN);
}

.restTextLarge {
  font-family: "Bungee-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16pt;
  text-align: center;
  line-height: 14px;
  color: var(--BASE_WHITE);
  text-transform: uppercase;
}

.restTracker {
  justify-content: center;
  border-radius: 2px;
  padding: 5px;
  margin-bottom: 5px;
}

.restAndInfoBlock {
  align-content: stretch;
  /* justify-content: space-between; */
  min-width: 230px;
  flex-direction: column;
  justify-content: space-evenly;
}

.infoTracker {
  align-items: center;
}

.infoTextLarge {
  font-family: "Bungee-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16pt;
  text-align: center;
  line-height: 20px;
  text-transform: uppercase;
}

.infoTextSmall {
  font-family: "Oswald-Regular";
  font-style: normal;
  /* text-align-vertical: center; */
  font-size: 14pt;
  line-height: 20px;
}

.lineForSetAndRep {
  flex-direction: row;
  align-items: center;
}

.arrowHeaderText {
  text-align: center;
  font-family: "Bungee-Regular";
  font-style: normal;
  font-size: 10pt;
  color: var(--BASE_ACCENT);
}

.arrowText {
  font-family: "Bungee-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 8pt;
  text-align: center;
  line-height: 10px;
  text-transform: uppercase;
}
