.sliderGroup {
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
}

.sliderRangeText {
  flex-direction: row;
  justify-content: space-between;
}
