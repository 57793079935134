.welcomeIcon {
  height: 150px;
  width: 200px;
  align-self: center;
}

.imageContainer {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.guideWideImage {
  height: 45px;
  width: 300px;
}

.guideWiderImage {
  height: 25px;
  width: 300px;
}

.guideSmallImage {
  height: 40px;
  width: 40px;
}

.guideMediumImage {
  height: 85px;
  width: 120px;
}

.guideLargeSquareImage {
  height: 130px;
  width: 130px;
}

.guideLargeSlightTallerImage {
  height: 190px;
  width: 300px;
}

.guideLargeImage {
  height: 180px;
  width: 300px;
}

.guideShrunkLargeImage {
  height: 110px;
  width: 185px;
}

.guideShrunkLargeSlightWiderImage {
  height: 140px;
  width: 180px;
}

.guideLargeSlightWiderImage {
  height: 170px;
  width: 300px;
}

.guideLargeSlightWidestImage {
  height: 160px;
  width: 300px;
}

.guideLargeWideImage {
  height: 150px;
  width: 300px;
}

.guideLargeBitWideImage {
  height: 147px;
  width: 300px;
}

.guideLargeWiderImage {
  height: 140px;
  width: 300px;
}

.guideLargeNearWidestImage {
  height: 120px;
  width: 300px;
}

.guideShrunkLargeNearWidestImage {
  height: 90px;
  width: 220px;
}

.guideLargeWidestImage {
  height: 110px;
  width: 300px;
}

.guideImageBorder {
  border-radius: 5px;
  border: 2px solid var(--BASE_ACCENT);
}

.guide-text {
  margin-top: 10px;
  font-family: "Oswald-Regular";
  font-size: 12pt;
  line-height: 20px;
}

.guide-bold-text {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  line-height: 20px;
  color: var(--BASE_ACCENT);
}

.dark .guide-bold-text {
  color: white;
}
