.contact-form {
  background-color: #ffffff;
  padding: 5% !important;
  border-radius: 5px;
}

.contact-form > div {
  color: #000000;
}

.contact-container {
  margin-bottom: 5%;
}

.contact-container > h1 {
  margin-top: 5%;
  margin-bottom: 5%;
}
