.principles-section {
  margin-bottom: 10px;
}

.principles-text {
  margin-top: 10px;
  font-family: "Oswald-Regular";
  font-size: 12pt;
  line-height: 20px;
}

.principles-bold-text {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  line-height: 20px;
  color: var(--BASE_ACCENT);
}

.dark .principles-bold-text {
  color: white;
}
