div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dark {
  background-color: var(--BASE_BLACK);
  color: var(--BASE_WHITE);
}

.light {
  background-color: var(--BASE_LVL_TWO_GRAY);
  color: var(--BASE_BLACK);
}
