.chart {
  align-items: center;
  cursor: pointer;
}

.tooltipStyle {
  font-family: "BenchNine-Regular";
  font-style: normal;
  font-weight: 400;
  fill: var(--BASE_WHITE);
  font-size: 13pt;
}

.tooltipContainer {
  background-color: var(--BASE_ACCENT);
  width: 120px;
  top: 10%;
  border-radius: 4px;
  padding: 4px;
}

.tooltipText {
  font-family: "BenchNine-Regular";
  font-style: normal;
  font-weight: 800;
  font-size: 12pt;
  color: var(--BASE_WHITE);
  text-align: center;
  margin-bottom: 2px;
}

.tooltipBoxValue {
  font-family: "BenchNine-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12pt;
  text-align: center;
  color: var(--BASE_WHITE);
}

.tooltipBoxTitle {
  font-family: "BenchNine-Regular";
  font-style: normal;
  font-weight: 800;
  font-size: 12pt;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--BASE_WHITE);
  align-self: center;
}

.tooltipBoxTextBackground {
  margin-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
}

.boxRow {
  flex-direction: row;
  justify-content: space-evenly;
}

.tooltipBox {
  width: 30%;
}

.tagContainer {
  margin-top: 4px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tagBox {
  border-radius: 2px;
  background-color: var(--BASE_GREEN);
  border-color: var(--BASE_BLACK);
  border-width: 0.5px;
  padding-bottom: 1px;
}

.tagTitle {
  font-family: "BenchNine-Regular";
  font-style: normal;
  font-weight: 800;
  font-size: 14pt;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--BASE_WHITE);
  align-items: center;
}
