.chart {
  align-items: center;
  cursor: pointer;
}

.tooltip-style {
  font-family: "BenchNine-Regular";
  fill: var(--BASE_WHITE);
  font-size: 13pt;
}

.tooltip-container {
  background-color: #6614b3;
  width: 120px;
  top: 10%;
  border-radius: 4px;
  padding: 4px;
}

.tooltip-text {
  font-family: "BenchNine-Regular";
  font-size: 12pt;
  color: var(--BASE_WHITE);
  text-align: center;
  margin-bottom: 2px;
}

.tooltip-box-value {
  font-family: "BenchNine-Regular";
  font-size: 12pt;
  text-align: center;
  color: var(--BASE_BLACK);
}

.tooltip-box-title {
  font-family: "BenchNine-Regular";
  font-size: 12pt;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--BASE_WHITE);
  align-self: center;
}

.tooltip-box-text-background {
  margin-top: 2px;
  padding-left: 2px;
  padding-right: 2px;
  border-radius: 2px;
}

.box-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.tooltip-box {
  width: 30%;
}

.tag-container {
  display: flex;
  margin-top: 4px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.tag-box {
  border-radius: 2px;
  background-color: var(--BASE_GREEN);
  border-color: var(--BASE_BLACK);
  border-width: 0.5px;
  padding-bottom: 1px;
}

.tag-title {
  font-family: "BenchNine-Regular";
  font-size: 12pt;
  padding-left: 2px;
  padding-right: 2px;
  color: var(--BASE_WHITE);
  align-items: center;
}
