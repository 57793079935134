.react-calendar {
  height: 400px;
  margin-bottom: 10px;
  background-color: var(--BASE_LVL_ONE_GRAY);
  border: none;
  font-family: "Oswald-Regular";
  width: 100%;
}

.dark .react-calendar {
  background-color: var(--BASE_LVL_ONE_DARK);
}

.react-calendar__month-view__weekdays,
.react-calendar__month-view__days,
.react-calendar__year-view__months,
.react-calendar__decade-view__years,
.react-calendar__century-view__decades,
.react-calendar__navigation {
  flex-direction: row;
}

.dark .react-calendar__navigation__label__labelText {
  color: var(--BASE_WHITE);
  background: var(--BASE_LVL_ONE_DARK) !important;
}

.dark .react-calendar__navigation__label:hover {
  background: var(--BASE_LVL_TWO_DARK) !important;
}

.dark .react-calendar__navigation__label {
  background: var(--BASE_LVL_ONE_DARK) !important;
}

.dark .react-calendar__navigation__arrow {
  color: var(--BASE_WHITE);
  background: var(--BASE_LVL_ONE_DARK) !important;
}

.dark .react-calendar__navigation__arrow:hover {
  color: var(--BASE_WHITE);
  background: var(--BASE_LVL_TWO_DARK) !important;
}

.react-calendar__tile--now {
  background: var(--BASE_WHITE) !important;
  border-radius: 3px;
  color: var(--BASE_BLACK) !important;
}

.react-calendar__tile--active,
.react-calendar__tile--hasActive {
  background: var(--BASE_ACCENT) !important;
  color: var(--BASE_WHITE) !important;
  border-radius: 3px;
}

.dark .react-calendar__tile {
  color: var(--BASE_WHITE) !important;
}

.dark .react-calendar__tile:hover {
  background: var(--BASE_LVL_TWO_DARK) !important;
}

.react-calendar__month-view__days__day {
  color: var(--BASE_BLACK);
}

.dark .react-calendar__month-view__days__day {
  color: var(--BASE_WHITE);
}

.react-calendar__month-view__days {
  height: 300px;
}
