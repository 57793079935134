.card-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
}

.card-header-center-box {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  line-height: 15px;
}

.card-header-title {
  font-family: "Bungee-Regular";
  font-size: 14pt;
  color: var(--BASE_ACCENT);
}

.dark .card-header-title {
  color: white;
}

.card-header-info {
  font-family: "BenchNine-Regular";
  font-size: 1.53em;
}

.dark .card-header-info {
  color: #c9c5c5;
}

.show-active {
  border-width: 0px;
  border-bottom-width: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 3px;
  margin-bottom: -2px;
  border-bottom-color: #6614b3;
  border-style: solid;
}
