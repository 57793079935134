.currentDateText {
  text-align: center;
}

.dateHeader {
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.innerActiveDate {
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
}

.today {
  align-items: center;
  flex-direction: row;
  /* align-self: flex-start; */
}

.navLabel {
  text-align: center;
}

.barContainer {
  flex-direction: row;
  justify-content: center;
  margin-bottom: -5px;
  margin-top: -5px;
}

.barArrows {
  margin-top: -4px;
  margin-bottom: -10px;
}

.barArrowLeft {
  margin-right: -15px;
  margin-left: -15px;
}

.barArrowRight {
  margin-right: -15px;
  margin-left: -15px;
}
