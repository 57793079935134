.container {
  width: 95%;
  margin-top: 10px;
  margin-bottom: 10px;
  flex: 1;
}

.card-container {
  display: flex;
  justify-content: space-evenly;
}

.context-section {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
}

.interaction-container {
  display: flex;
  vertical-align: middle;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.button-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex-basis: 88%;
  text-align: center;
  /* align-items: center; */
  justify-content: center;
  /* width: 80%; */
  width: 100%;
  align-self: center;
  margin-bottom: 10px;
}

.flex-end {
  align-self: flex-end;
}

.noTopMargin {
  margin-top: 0px;
}

.noBottomMargin {
  margin-bottom: 0px;
}

.nowrap {
  flex-wrap: nowrap;
}

.full-width {
  width: 100%;
}

.butterfly-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.interaction-subset-left {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  width: 40%;
}

.interaction-subset-center {
  display: flex;
  flex-direction: column;
}

.interaction-subset-right {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 40%;
}

.top-header-text {
  text-align: center;
}

.bonus-margin {
  margin-left: 1%;
}

.empty-text {
  font-family: "Oswald-Regular";
  font-size: 14pt;
  margin-bottom: 0;
  margin-top: -2;
  align-items: center;
}

.card-content-button {
  margin: 1px;
  height: 40px;
  border-radius: 3px;
  border: none;
  background-color: "grey";
  justify-content: center;
  width: 70px;
}

.card-content-button-butterfly {
  margin: 1px;
  height: 40px;
  border-radius: 3px;
  border: none;
  background-color: "grey";
  justify-content: center;
  width: 30%;
}

.interaction-subset-left {
  justify-content: left;
}

.interaction-subset-right {
  justify-content: right;
}

.interaction-subset-center .card-content-button-butterfly {
  width: 100%;
  min-width: 70px;
}

.card-button-text {
  font-family: "Bungee-Regular";
  font-size: 0.7em;
  justify-content: center;
  text-transform: uppercase;
  color: white;
}

.card-header-text-small-centered {
  font-family: "Bungee-Regular";
  font-size: 12pt;
  color: var(--BASE_ACCENT);
  align-items: center;
  text-align: center;
}

.card-header-text-small-centered-black {
  font-family: "Bungee-Regular";
  font-size: 12pt;
  color: var(--BASE-BLACK);
  align-items: center;
  text-align: center;
}

.modal-header-text-small {
  font-family: "Bungee-Regular";
  font-size: 12pt;
  color: var(--BASE_ACCENT);
  align-items: center;
  line-height: 16px;
  text-align: center;
  margin-bottom: 10px;
}

.dark .modal-header-text-small {
  color: var(--BASE_WHITE);
}

.dark .card-header-text-small-centered {
  color: var(--BASE_WHITE);
}

.card-header-text-smaller {
  text-align: center;
  font-family: "Bungee-Regular";
  font-size: 10pt;
}

.card-text-small {
  font-family: "Oswald-Regular";
  font-size: 14pt;
}

.card-text-small .centered {
  text-align: center;
}

.glyph-button {
  background-color: var(--BASE_LVL_TWO_GRAY);
  border: none;
  color: var(--BASE_ACCENT);
  cursor: pointer;
}

.row-glyph-button {
  background-color: var(--BASE_LVL_TWO_GRAY);
  border: none;
  cursor: pointer;
  display: flex;
  color: var(--BASE_WHITE);
  height: 28px;
  border-radius: 4px;
  width: 35px;
  margin-left: 0.5px;
  margin-right: 0.5px;
  align-items: center;
  justify-content: center;
}

.dark .row-glyph-button {
  color: white;
}

.dark .glyph-button {
  color: white;
  background-color: var(--BASE_LVL_ONE_DARK);
}

.glyph-button-text-row {
  line-height: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
