.cardContextText {
  font-family: "Oswald-Regular";
  font-size: 14pt;
  text-align: center;
}

.FurtherReadingRow {
  line-height: 25px;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  margin-bottom: 20px;
}
