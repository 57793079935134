.viewContainer {
  margin-top: 10px;
}

.inputContainer {
  margin-top: 10px;
}

.actionNameContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.popUpSections {
  width: 100%;
}
