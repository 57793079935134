.cardMastText {
  font-family: "Oswald-Regular";
  font-size: 12pt;
  margin-bottom: 2%;
}

.boldText {
  font-family: "Bungee-Regular";
  font-size: 12pt;
}

.light .boldText {
  color: var(--BASE_ACCENT);
}

.dark .boldText {
  color: white;
}

.mastContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 5%;
  margin-right: 5%;
  line-height: 24px;
}
