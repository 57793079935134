.user-agreement {
  flex-grow: 1;
  background-color: var(--BASE_WHITE);
  color: var(--BASE_BLACK);
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  overflow-y: scroll;
  overflow: auto;
  scroll-behavior: smooth;
  padding-left: 15px;
  max-height: 100%;
}

.user-agreement pre {
  overflow: auto;
  white-space: pre-wrap; /* css-3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  scroll-behavior: smooth;
}
