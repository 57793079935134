.fastBlockContainer {
  display: flex;
  flex-direction: row;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.milestoneContainer {
  justify-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 15px;
  width: 33%;
}

.clock-start {
  margin-left: 8%;
}

.milestoneHeaderHourText {
  text-align: center;
  font-family: "Bungee-Regular";
  font-size: 14pt;
  margin-top: 10px;
  margin-bottom: 10px;
}

.milestoneHeaderText {
  text-align: center;
  font-family: "Bungee-Regular";
  font-size: 10pt;
  color: var(--BASE_ACCENT);
}

.milestoneText {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  text-align: center;
  text-transform: uppercase;
}

.fastBlockContainerColumn {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.blockTimelineHeader {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.blockTimeline {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.blockTimelineViewer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.blockTimelineItem {
  margin: 1px;
  width: 12.5%;
  height: 40px;
  border: none;
  border-radius: 3px;
  justify-content: center;
}

.blockTimelineContainer {
  width: 100%;
}

.blockTimelineItemText {
  font-family: "Bungee-Regular";
  font-size: 0.75em;
  text-transform: uppercase;
  color: var(--BASE_WHITE);
  padding: 0px;
}

.blockTimelineDayHeader {
  font-family: "Bungee-Regular";
  font-size: 10pt;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0px;
}

.innerCircleFont {
  text-align: center;
  font-family: "Bungee-Regular";
  line-height: 18px;
}

.emptyTextLowered {
  font-family: "Oswald-Regular";
  font-size: 12pt;
  color: var(--BASE_BLACK);
  text-align: center;
}

.further-reading-button {
  height: 30px;
  border: none;
  color: var(--BASE_WHITE);
  background-color: var(--BASE_ACCENT);
  border-radius: 3px;
  padding: 0px;
  cursor: pointer;
}
